<template>
  <div class="container">
    <BasicTitle :title="$route.name" />
    <BasicSubtitle subtitle="Los campos señalados con (*) son obligatorios." />
    <div class="container-form">
      <BasicLabel label="Torre *" />
      <BorderSelect
        v-model="apartment.data.towerId"
        label="Torre *"
        :options="apartment.towers"
      />
      <FormError
        :show="apartment.rules.towerId"
        message="Seleccione una opción"
      />
      <BasicLabel label="Número *" />
      <BorderInput
        v-model="apartment.data.number"
        label="Número *"
        @keyup.enter="onSave"
      />
      <FormError
        :show="apartment.rules.number"
        message="Ingrese un número de apartamento"
      />
      <BasicLabel v-if="false" label="Prorrateo" />
      <BorderInput
        v-if="false"
        v-model="apartment.data.apportionment"
        label="Prorrateo"
        @keyup.enter="onSave"
      />
      <FormError :show="apartment.rules.apportionment" />
      <BasicLabel v-if="false" label="Habitaciones" />
      <BorderInput
        v-if="false"
        v-model="apartment.data.bedrooms"
        label="Habitaciones"
        @keyup.enter="onSave"
      />
      <FormError :show="apartment.rules.bedrooms" />
      <BasicLabel v-if="false" label="Deuda" />
      <BorderInput
        v-if="false"
        v-model="apartment.data.totalDebt"
        type="number"
        label="Deuda"
        @keyup.enter="onSave"
      />
      <FormError :show="apartment.rules.totalDebt" />
    </div>
    <PrimaryButton label="Guardar" :click="onSave" />
  </div>
</template>

<script>
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { openMode, actions, currency } from "../../constants";

export default {
  components: {
    BasicTitle,
    BasicLabel,
    BasicSubtitle,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    FormError,
    PrimaryButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const apartment = reactive({
      towers: [],
      data: {
        id: "",
        towerId: "",
        number: "",
        apportionment: "",
        bedrooms: "",
        totalDebt: "",
      },
      rules: {
        towerId: false,
        number: false,
        apportionment: false,
        bedrooms: false,
        totalDebt: false,
      },
    });

    const validate = () => {
      let valid = true;

      if (!apartment.data.towerId) {
        apartment.rules.towerId = true;
        valid = false;
      }
      if (!apartment.data.number) {
        apartment.rules.number = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const request = {
        id: apartment.data.id,
        towerId: apartment.data.towerId,
        number: apartment.data.number,
        apportionment: apartment.data.apportionment,
        bedrooms: apartment.data.bedrooms,
        totalDebt: apartment.data.totalDebt,
      };

      let response = null;
      if (store.state.openMode === openMode.MODIFY) {
        response = await store.dispatch(
          actions.apartmentActions.update,
          request
        );
      } else {
        response = await store.dispatch(
          actions.apartmentActions.create,
          request
        );
      }
      store.commit("setLoading", false);
      if (response.ok) {
        store.commit("setSelectedApartments", []);
        router.push("/apartamentos/listado");
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    onBeforeMount(() => {
      if (!store.state.openMode) router.push("/apartamentos/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      const building = store.state.auth.user.buildings.find(
        (item) => item.id === store.state.general.selectedBuilding
      );
      if (building) {
        apartment.towers = building.towers;
      }
      if (store.state.openMode === openMode.MODIFY) {
        const selected = store.state.apartment.selectedApartments[0];
        apartment.data.id = selected.id;
        apartment.data.towerId = selected.tower.id;
        apartment.data.number = selected.number;
        apartment.data.apportionment = selected.apportionment;
        apartment.data.bedrooms = selected.bedrooms;
        apartment.data.totalDebt = selected.totalDebt;
      }
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (apartment.data.towerId) apartment.rules.towerId = false;
      if (apartment.data.number) apartment.rules.number = false;
      if (apartment.data.apportionment) apartment.rules.apportionment = false;
      if (apartment.data.bedrooms) apartment.rules.bedrooms = false;
      if (apartment.data.totlaDebt) apartment.rules.totlaDebt = false;
    });

    return { store, apartment, onSave };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
</style>
